@font-face {
	font-family: 'Metropolis';
	font-style: normal;
	font-weight: 400;
	src:
		local('Metropolis-Medium'),
		url(./fonts/Metropolis-Medium.otf) format('opentype');
}

@font-face {
	font-family: 'Avenir';
	font-style: normal;
	font-weight: 400;
	src:
		local('Avenir'),
		url(./fonts/Avenir.ttc) format('truetype');
}

$form-background-color: #f8efe4;
$form-text-color: #0b0401;
$highlight-color: #bb7dad;
$highlight-hover-color: #d68fc6;
$error-color: #FF0000;
$link-color: #2b2621;
$link-hover-color: #5f442c;;

.App {
	text-align: center;
	color: $form-text-color;
	font-family: Avenir, sans-serif;

	.agreeSection {
		margin-bottom: 1em;
		padding-left: 7%;

		div {
			float: left;
			margin: 0 0 1em .5em;
			text-align: left;
			max-width: 85%;
		}

		input {
			float: left;
			&.invalidCheckbox {
				outline: 2px solid $error-color;
			}
		}
	}
	
	.topLogo {
		margin-top: 2em;
		left: 50%;
		max-width: 535px;
	}

	.flag {
		margin-bottom: 0;
		width: 100%;
	}

	.mainSection {
		background-color: $form-background-color;
		border-top: 1px solid #333333;
		margin-top: -6px;
		padding-top: 1em;
		width: 100%;

		& > div {
			margin: 0 15%;
		}

		@media (max-width: 768px) {
			& > div {
				margin: 0 5%;
			}
		}

		a {
			color: $link-color;
			&:hover {
				color: $link-hover-color;
			}
		}

		button {
			background-color: $highlight-color;
			border: 0px;
			border-radius: 5px;
			color: #ffffff;
			cursor: pointer;
			font: {
				size: 32px;
				weight: bold;
			}
			padding: 8px 0 7px 0;
			text-transform: uppercase;
			width: 80%;

			&:hover {
				background-color: $highlight-hover-color;
			}
		}

		@media (max-width: 420px) {
			button {
				font-size: 24px;
			}
		}

		h3 {
			font: {
				size: 26px;
			}
		}

		h4 {
			font-size: 26px;
			margin-block-start: 1em;
    		margin-block-end: .5em;
		}

		p {
			font-size: 18px;
			line-height: 1.5em;

			&.smallText {
				//font-size: 14px;
			}
		}

		form {
			padding: 0 20%;

			@media (max-width: 768px) {
				padding: 0 10%;
			}

			input[type="text"] {
				background-color: #ffffff;
				border: 1px solid $form-background-color;
				border-radius: 5px;
				font-size: 1.3em;
				height: 2em;
				margin-bottom: .7em;
				padding: 1px 0 0 .6em;
				width: 80%;

				&.invalidInput {
					border: 2px solid $error-color;
				}
			}

			.identifySection {
				font: {
					size: 24px;
					style: italic;
				}
				margin-bottom: .6em;

				// TODO: remove all these weird class names from old styling
				.radio-gradient {
					margin-right: 14px;
				}

				.radio-before {
					margin-left: 14px;
				}

				@media (max-width: 425px) {
					font-size: 22px;

					.radio-gradient {
						margin-right: 9px;
					}
	
					.radio-before {
						margin-left: 9px;
					}
				}
			}

			.invalidRadio .identifySection {
				border: 2px solid $error-color;
			}
		}
	}

	.social-icon {
		margin: 0 5px;
	}

	footer {
		padding: 2em 0 1em 0;
		nav ul {
			list-style: none;
			margin: 0;
			padding: 0;
			width: 100%;
			text-align: centerc;

			& li {
				display: inline-block;
				margin: 10px;

				& a {
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}